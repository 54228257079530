import produce from "immer";
import TableConfig from "@/pages/EltSyncs/modules/TableConfig";
import { useToast } from "@/providers/ToastProvider";
import { useEffect } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";

import { useStepsContext } from "../../../stepper/useSteps";
import { Content, Footer, Heading, SubHeading } from "../../components/Layout";
import { NextButton, PrevButton } from "../../components/NavButton";
import { useDataSourceStepContext } from "../../contexts/DataSourceStepsContext";
import { useDataSourceStateContext } from "../../step-state";

export function TablesStepContainer() {
  const { state, setState } = useDataSourceStateContext();
  const { connection } = useDataSourceStepContext();
  const { onNextStep } = useStepsContext();
  const toast = useToast();

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      sourceStreams: state.tables.value.sourceStreams,
    },
  });

  const { isDirty, isValid } = useFormState({ control: formMethods.control });

  useEffect(() => {
    setState(
      produce((state) => {
        state.tables.isDirty = isDirty;
      }),
    );
  }, [isDirty, setState]);

  return (
    <form
      onSubmit={formMethods.handleSubmit((data) => {
        setState(
          produce((state) => {
            state.tables = {
              value: {
                sourceStreams: data.sourceStreams,
              },
              isValid: true,
              isDirty: false,
            };
          }),
        );
        onNextStep();
      })}
      className="flex h-full w-full flex-col"
    >
      <Content className="mt-2 pt-0">
        <div>
          <Heading>Select what data to sync</Heading>
          <SubHeading>
            What tables do you want to sync and be accessible
          </SubHeading>
        </div>
        <Controller
          name="sourceStreams"
          control={formMethods.control}
          render={({ field }) => (
            <TableConfig
              sourceId={state.source.value.sourceId}
              sourceSettings={state.sourceSettings.value.sourceSettings}
              connection={connection ?? null}
              mode="create"
              initialValues={Object.fromEntries(field.value)}
              onChange={(sourceStreams) => {
                field.onChange(new Map(Object.entries(sourceStreams)));
              }}
              onError={(error) => {
                toast(
                  `Could not fetch tables for "${connection?.label}"`,
                  error.message,
                  "error",
                );
              }}
            />
          )}
          rules={{
            validate: (value) => {
              if (!value.size) {
                return false;
              }
              const streams = Array.from(value.values());
              if (streams.some((s) => s.isIncrementalInvalid)) {
                return false;
              }
              return true;
            },
          }}
        />
      </Content>
      <Footer className="flex-row-reverse">
        <NextButton isDisabled={!isValid}>Next</NextButton>
        <PrevButton />
      </Footer>
    </form>
  );
}
