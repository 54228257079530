import { EltSyncQuery, OrchestrationSchedulerType } from "@/apollo/types";
import { SourceStreamsState } from "@/features/elt/reducers/sourceStreamsReducer";
import produce from "immer";

type Substream = {
  hashedColumns: string[];
  excludedColumns: string[];
};

export type SourceStream = {
  primaryKeys: string[];
  incrementalPointerId: string;
  hashedColumns: string[];
  excludedColumns: string[];
  substreams: { [key: string]: Substream };
  hasBeenAutoMapped: boolean;
  fullSyncAlways: boolean;
  fullSyncAtMidnight: boolean;
  protectedFromFullSync: boolean;
  mode: "MERGE" | "APPEND" | "FULL";
  isIncrementalInvalid?: boolean;
};

export type EltSyncStateType = {
  syncId?: string; // undefined if creating a new sync
  sourceId: string;
  sourceStreams: { [key: string]: SourceStream | undefined };
  scheduleKey?: string;
  active: boolean;
  startDate?: string | null;
  sourceSettings: Record<string, any>;
  destinationSchemaName: string;
  destinationSchemaEditable?: boolean;
  destinationSchemaValid: boolean;
  destinationSchemaErrorMessage?: string;
  schedulerType: OrchestrationSchedulerType;
  orchestrationWorkflowId?: string;
};

export type EltSyncActionType =
  | {
      type: "initialize";
      payload: EltSyncStateType;
    }
  | {
      type: "set_source_streams";
      payload: SourceStreamsState;
    }
  | { type: "change_schedule"; payload: string | undefined }
  | { type: "change_source"; payload: string }
  | { type: "change_start_date"; payload: string }
  | { type: "change_source_settings"; payload: { key: string; value: any } }
  | {
      type: "change_destination_schema";
      payload: { value: string; destinationSchemaEditable?: boolean };
    }
  | {
      type: "toggle_destination_schema_editable";
    }
  | {
      type: "update_scheduler_type";
      payload: {
        schedulerType: OrchestrationSchedulerType;
        orchestrationWorkflowId?: string;
      };
    };

export const initialState: EltSyncStateType = {
  sourceId: "",
  scheduleKey: "0 0 * * *",
  sourceStreams: {},
  active: false,
  startDate: null,
  sourceSettings: {},
  destinationSchemaName: "",
  destinationSchemaEditable: false,
  destinationSchemaValid: true,
  schedulerType: OrchestrationSchedulerType.Local,
};

export const initSyncState: ({ eltSync }: EltSyncQuery) => EltSyncStateType = ({
  eltSync,
}) => {
  return {
    syncId: eltSync?.id,
    sourceSettings: eltSync.config?.sourceSettings || {},
    config: eltSync.config,
    startDate: eltSync.startDate,
    scheduleKey: eltSync.syncInterval ?? undefined,
    sourceId: eltSync.sourceConnectionId,
    active: eltSync.active,
    sourceStreams: eltSync.sourceStreams.reduce<Record<string, SourceStream>>(
      (prev, { __typename, ...cur }) => ({
        ...prev,
        [cur.name]: {
          substreams: cur.substreams.reduce<Record<string, Substream>>(
            (all, { __typename, name, ...substream }) => ({
              ...all,
              [name]: substream,
            }),
            {},
          ),
          primaryKeys: cur.incrementalPrimaryKeyName ?? [],
          incrementalPointerId: cur.incrementalPointerId ?? "",
          hashedColumns: cur.dataFilters
            .filter((d) => d.transformation === "hash")
            .map((d) => d.sourceStreamObjectKey),
          excludedColumns: cur.dataFilters
            .filter((d) => d.transformation === "ignore")
            .map((d) => d.sourceStreamObjectKey),
          fullSyncAlways: cur.fullSyncAlways ?? false,
          fullSyncAtMidnight: cur.fullSyncAtMidnight ?? false,
          protectedFromFullSync: cur.protectedFromFullSync ?? false,
          mode: cur.operationMode === "APPEND" ? "APPEND" : "MERGE",
          hasBeenAutoMapped: false,
        },
      }),
      {},
    ),
    hasBeenAutoMapped: [],
    destinationSchemaName: eltSync.destinationSchemaName ?? "",
    destinationSchemaEditable: !eltSync.destinationSchemaName,
    destinationSchemaValid: true,
    schedulerType: eltSync.schedulerType,
    orchestrationWorkflowId: eltSync.orchestrationWorkflow?.id ?? undefined,
  };
};

const reducer = (
  state: EltSyncStateType,
  action: EltSyncActionType,
): EltSyncStateType => {
  switch (action.type) {
    case "initialize":
      return action.payload;

    case "set_source_streams": {
      return produce(state, (draft) => {
        draft.sourceStreams = action.payload;
      });
    }

    case "change_schedule":
      return { ...state, scheduleKey: action.payload };
    case "change_source": {
      const idDidNotChange = action.payload === state.sourceId;
      if (idDidNotChange) return state;
      return {
        ...state,
        sourceId: action.payload,
        sourceStreams: {},
        sourceSettings: {},
        startDate: null,
        destinationSchemaName: "",
        destinationSchemaEditable: true,
        destinationSchemaValid: false,
        destinationSchemaErrorMessage: undefined,
      };
    }
    case "change_source_settings": {
      return {
        ...state,
        sourceSettings: {
          ...state.sourceSettings,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "change_start_date": {
      return { ...state, startDate: action.payload };
    }

    case "change_destination_schema": {
      const { valid, errorMessage } = validateSchemaName(action.payload.value);
      return {
        ...state,
        destinationSchemaName: action.payload.value,
        destinationSchemaValid: valid,
        destinationSchemaErrorMessage: errorMessage,
        destinationSchemaEditable: action.payload.destinationSchemaEditable,
      };
    }

    case "update_scheduler_type": {
      return {
        ...state,
        schedulerType: action.payload.schedulerType,
        orchestrationWorkflowId: action.payload.orchestrationWorkflowId,
      };
    }

    case "toggle_destination_schema_editable":
      return {
        ...state,
        destinationSchemaEditable: !state.destinationSchemaEditable,
      };

    default:
      return state;
  }
};

export default reducer;

const validateSchemaName = (input?: string | null) => {
  if (!input?.length)
    return {
      valid: false,
      errorMessage: "Cannot be empty",
    };

  if (!/^[\w_]*$/.test(input))
    return {
      valid: false,
      errorMessage: "Can only contain letters and underscore",
    };

  if (/__/.test(input)) {
    return {
      valid: false,
      errorMessage: "Cannot have double underscores",
    };
  }

  return { valid: true };
};
